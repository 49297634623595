import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import AboutHeader from '../components/about_header'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Link from '../components/link'
import Container from '../components/container'
import Anchor from '../components/anchor'

const PressReleasesPage = () => {
  
  const { hash } = useLocation()
  const activeRelease = hash ? hash.substr(1) : 'release_15' 
  const [isActive, setActive] = useState(activeRelease)
  
  useEffect(() => {
    if (hash) {
      document.getElementById(activeRelease).scrollIntoView()
    }
  })

  const handleCollapse = id => {
    setActive(null)
    document.getElementById(id).scrollIntoView()
  }

  return (
    <Layout>
      <Seo
        title='Press Releases'
        description='We’re making the headlines. Discover what’s new and newsworthy in the automotive industry.'
      />
      <AboutHeader
        title='Making Headlines'
        subtitle='Discover what’s new and newsworthy.'
        isPress={true}
      />
      <div className='Section Section--condensed'>
        <Container size='md'>
          <Anchor id='release_15' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              May 10, 2021
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster Leans Into Conversational Commerce With Launch Of Express Store Live
            </h1>
            <div className='mt-2' style={isActive === 'release_15' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_15'
                onClick={() => setActive('release_15')}
              />
            </div>
            <div style={isActive !== 'release_15' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                With an industry focus on how to improve the customer experience, Roadster’s new managed chat service flips traditional models on their heads by instantly connecting prospective buyers with Roadster’s team of automotive experts who can answer their in-depth car questions and move them further down the purchasing funnel than ever before
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, Calif. — May 10, 2021</b> — Roadster, the leader in commerce solutions for car buying and leasing, today announced the rollout of Express Store Live, the company’s new cutting edge managed chat service that connects potential car buyers directly with Roadster’s team of automotive experts to create more meaningful and more impactful exchanges from the moment consumers reach out to inquire about a car. Building upon its already successful Express Storefront digital sales platform, where dealers can connect the dots between what the customers did at home and their in-store experience, the company will now bring conversational commerce to an entirely new level by guiding customers further down the purchase funnel through informed two-way conversations about vehicle pricing, incentives, rebates, F&I options, trade-in valuations and more.</p>
                <p>
                  “While chat has long been used by dealerships as a lead generation tool, consumers often find the process so disappointing, they abandon it before any meaningful information is exchanged,” said Andy Moss, CEO of Roadster. “With Express Store Live, and its technology platform powered by LivePerson, dealerships can now create the opposite effect. Express Store Live not only helps dealerships to engage with their customers at a rate that is double the industry average, but it helps them close at an exponentially higher rate. It's the managed chat service the industry has been waiting for.”
                </p>
                <p>
                  In early pilot tests, dealerships that employed Express Store Live were able to achieve a number of benefits. These include:
                </p>
                <ul>
                  <li><b>A faster response time:</b> while the average industry response time for managed chat is 15 seconds, Express Store Live was able to respond in just five seconds, which helped dealerships reduce consumer drop off by 64 percent.</li>
                  <li><b>More engagement:</b> by utilizing automotive experts to provide answers to customers’ initial questions, dealerships saw 85.5 percent of their customers continue engaging over chat, surfacing and addressing additional questions that helped dealerships guide them further down the purchase funnel. Average conversations lasted 26 minutes - more than double the industry average of 11 minutes.</li>
                  <li><b>Higher close rates:</b> Express Store Live dealers were able to more than double their close rates, at 35 percent as compared to the industry average of 16 percent. Assuming a $2000 gross profit per car, doubling the close rate can produce an additional $10-20,000 in monthly revenue.</li>
                </ul>
                <p>
                  Adding to the benefits, dealers also reported that with Express Store Live 79 percent of customers responded to their follow up. They were also able to answer lower funnel questions online, creating an operational efficiency for their dealership. This in turn also increased customer satisfaction by saving showroom time for the customer.
                </p>
                <p>
                  For more information on accessing Roadster’s Express Store Live, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_15')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_14' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              August 24, 2020
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster Elevates Digital Retailing By Enhancing Its Robust Digital Sales Platform Designed To Make The Entire Process Of Car Selling Easier
            </h1>
            <div className='mt-2' style={isActive === 'release_14' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_14'
                onClick={() => setActive('release_14')}
              />
            </div>
            <div style={isActive !== 'release_14' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                With an industry focus on how to improve the customer experience as well as how to save time on each transaction, Roadster doubles down on holistic omnichannel commerce platform that will help dealers achieve these goals by focusing on each aspect of the sale
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, Calif. — August 24, 2020</b> — Roadster, the leader in commerce solutions for car buying and leasing, today announced the rollout of its enhanced omnichannel commerce platform designed to help dealerships offer a more modernized and improved car buying experience, by holistically addressing every aspect of the sale.  Building upon its already successful in-store sales process, where Roadster’s Express Storefront empowers dealers to connect the dots between what the customers did at home and their in-store experience, the company will now introduce a suite of new remote selling products that will empower internet and BDC teams to guide customers further down the purchase funnel regardless of where they are.</p>
                <p>
                  “The industry has been focused on plug and play digital retailing products as a way to improve and modernize car buying but in order to really improve the customer experience, we have to empower dealership personnel with solutions that make it easier to sell,” said Andy Moss, CEO of Roadster. “Car shoppers don’t buy cars in a linear fashion and we can no longer treat them that way. We are proud to be introducing the next generation of our digital sales platform at such a crucial time in the industry, when dealers need the most efficient and effective way to engage consumers and seamlessly walk them through the purchase process from start to finish.”
                </p>
                <p>
                  As part of its new offering, Roadster will introduce Express Response, a lead response solution that allows the dealership to automatically respond to internet leads with intelligent and relevant information that encourages the customer to start the deal-making activity online.  Unlike legacy models, where consumers often get an inactionable auto-response to their lead submissions or where the dealership spends a significant amount of time sending detailed and individualized responses, Express Response increases customer engagement 24 hours a day by responding to all internet lead sources from one seamless platform. It empowers dealers to follow up on customer’s interest on any piece of new or used inventory, and respond directly with VIN specific information to help move customers down the purchase funnel faster. It improves transparency and consistency, providing customers with pricing that matches what appears on a dealer’s Express Store and links them directly to the appropriate VDP or SRP to ensure there are no surprises as they build the deal.
                </p>
                <p>
                  Express Response also gives dealers the ability to control their brand voice, cadence and timing of their messages, as well as track response metrics, from open rates, click through rates, session length and actions taken to measure and ensure customer engagement and satisfaction remains paramount. It also provides dealerships with unique visibility into a customer’s first interaction with their dealership, which is something that many dealerships have been unable to track until now.
                </p>
                <p>
                  In early pilots with 43 rooftops and 12 dealer groups representing makes including Kia, Buick GMC, Nissan, Mazda, CDJR, Toyota, Hyundai, Honda, Acura, Lexus, Mercedes-Benz, Volvo, Porsche, BMW, Land Rover, Jaguar, VW, Audi, and Ford Lincoln, Express Response vastly improved engagement rates. Express Response’s open rate is at 65 percent, compared to an industry average of 12.6 percent, and its click through rate is at 22 percent versus the industry average of 1.2 percent.*
                </p>
                <p>
                  “Express Response has significantly increased our customer engagement,” said Greg Nalewaja, General Manager at Midlands Honda. “Not only are we able to immediately collect information from customers that otherwise would have taken four or five salespeople to gather, but our closing ratio is 16% because we can move our customers down the purchase funnel faster and more efficiently.”
                </p>
                <p>
                  In addition to Express Response, Roadster will begin to roll out its enhanced Express Storefront experience, which is designed to give sales agents the flexibility to start orders and push tasks to customers throughout the buying experience. Unlike current models where customers are forced to go through a very linear and rigid online process regardless of their situation and a sales agent is left either forcing them to take steps that don’t make sense or having to take them out of it entirely to better accommodate their customer’s needs, Roadster’s enhanced Express Storefront experience moves sales agents from being reactive to being proactive, encouraging them to push appropriate purchase tasks including showcasing F&I when starting an order for better visibility and transaction rates in whatever order makes sense to the customer, while providing them with visibility into all of the customer’s activity and various deals created in one place for ease of engagement and follow up.
                </p>
                <p>
                  Customers are encouraged to complete as many or few of the steps of the transaction online or in-person, including having the choice of completing forms in whatever order makes sense at checkout, or simply reserve the car with a deposit and finish the process in store. The enhanced Express Storefront experience, allows sales agents to prompt customers when it is time to complete tasks together, including complete a credit app or trade in, request a deposit, ask for additional documentation and more. With remote sales gaining in popularity due to COVID-19, Roadster’s restructured selling experience provides dealers with the opportunity to save the customer and their sales team time by guiding customers through as much of the purchase process as possible before they come into the showroom.
                </p>
                <p>
                  Adding to its offering, the company will also roll out Express Store Live, powered by Live Person, providing dealerships with an efficient and digitized way to build relationships with their customers, even when their customer is at home. With Express Store Live, dealerships will have access to video conferencing and screen sharing capabilities to answer questions that their online buyer may have at any step of the process.
                </p>
                <p>
                  In addition, Roadster’s commerce platform will also integrate with Equity Mining Tools like Auto Alert to provide dealers with the ability to surface up the exact VIN that might be right for their current customers and give them the ability to reserve or purchase that vehicle from the comfort of home.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_14')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_13' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              July 06, 2020
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster Bolsters Sales Team with Appointment of Levon Sarkissian to VP of Business Development
            </h1>
            <div className='mt-2' style={isActive === 'release_13' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_13'
                onClick={() => setActive('release_13')}
              />
            </div>
            <div style={isActive !== 'release_13' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                After successfully building out the company’s best in class Dealer Success Team, Sarkissian will now make the strategic transition to overseeing the continued growth and development of Roadster’s OEM and Enterprise partnerships around the globe
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, Calif. — July 6, 2020</b> — Roadster, the leader in commerce solutions for car buying and leasing, today announced that Levon Sarkissian has been appointed to Vice President of Business Development. In his new role, Levon will oversee the continued growth and development of Roadster’s OEM and Enterprise partnerships, both in North America and around the globe.</p>
                <p>
                  Levon joins the Business Development team after building out Roadster’s industry-leading Dealer Success team to over 30 managers around the country. Under Levon’s leadership, Roadster’s Dealer Success team has collectively received some of the highest marks in vendor satisfaction (70+ Net Promoter Score) for training and integration. Roadster has also onboarded over 1,500 rooftops across the country, providing each with in-depth product training and sales process planning to ensure each dealership meets the goals set forth at the onset of the relationship. Additionally, Roadster has developed a full suite of self-service tools accessible through Roadster Academy to ensure sales agents are able to get certified on the platform and have resources available for continued education along the way.
                </p>
                <p>
                  “Since Joining Roadster over 5 years ago, Levon has shown excellent leadership and a relentless passion for helping retailers modernize their sales process with omnichannel commerce technology,” said Andy Moss, CEO of Roadster “As OEMs continue to roll out programs to support their dealerships’ journey toward modern retailing, it only makes sense to partner them with a strategic leader like Levon who has been optimizing process and strategy with some of the largest dealerships and dealer groups across the country.”
                </p>
                <p>
                  “The Business Development team at Roadster is a passionate group of individuals who not only have created strong bonds with OEMs across the U.S. but are now also working with OEM partners globally,” said Levon Sarkissian, VP Business Development.“I am humbled by the work they have done to both become certified partners with some of the leading OEMs as well as our continued collaboration across all brands and enterprise partners. I look forward to working alongside these noteworthy OEMs to take our partnership to the next level.”
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_13')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_12' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              June 27, 2018
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster Announces $15M in Funding to Satisfy High Demand for Modern Omnichannel Commerce Solution that Streamlines the Car  Buying and Selling Process
            </h1>
            <div className='mt-2' style={isActive === 'release_12' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_12'
                onClick={() => setActive('release_12')}
              />
            </div>
            <div style={isActive !== 'release_12' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                Round led by Costanoa Ventures will help Roadster accelerate growth and deliver its branded suite of commerce solutions to even more dealers, dealer groups and OEMs
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, Calif. — June 27, 2018</b> — Roadster, the leader in commerce solutions for car buying and leasing, today announced it has raised an additional $15M in funding, led by existing investor Costanoa Ventures. These funds will be used to help Roadster scale its operations, expand its field and sales teams, amplify marketing efforts and further evolve its suite of Express Storefront® products to meet and serve explosive customer demand from major dealerships, dealership groups and OEMs.</p>
                <p>
                  “Over the past year, Roadster has transformed from a Silicon valley startup into a powerful force in the automotive industry,” said Andy Moss, Roadster’s CEO. “Our suite of branded Express products have helped dealers, dealer groups and OEMs significantly improve operating results and we have become the go-to omni-channel commerce solution for dealers looking to streamline their sales processes. With over 300 rooftops, including relationship programs with automotive groups like Lithia and our participation as one of four of Lexus’ certified digital retail partners, it’s clear we’re onto something big.”
                </p>
                <p>
                  Unlike other solutions in the marketplace that address one aspect of car buying, Roadster’s solution empowers dealerships and dealer groups to offer a streamlined, end-to-end car buying and selling experience. The company’s current suite of products include:
                </p>
                <ul>
                  <li>Express Storefront®, a branded online storefront and app that enables dealerships to seamlessly automate every step of the buying process by merchandising and selling all of their new, CPO and pre-owned inventory, from online to in-store</li>
                  <li>Express Marketplace®, a platform that empowers dealer groups large and small to maximize their regional advertising efforts by driving car buyers to the group’s own branded microsite where they can discover, shop for and purchase any of the dealer group’s inventory</li>
                  <li>Express Trade®, a solution that allows car buyers to submit their trade information prior to entering a dealership and dealerships to centralize the valuation process and gain complete control over what they offer their customers</li>
                </ul>
                <p>
                  “While Silicon Valley is investing heavily in companies that offer a modern approach to car buying, no one is approaching the industry like Roadster,” said Greg Sands, Founder and Managing Partner at Costanoa Ventures. “The company merges its deep understanding of commerce with its in-depth knowledge of how dealerships work to deliver a solution that brings new levels of efficiency, reach and transparency to car buying. We are proud to continue to partner with the Roadster team.”
                </p>
                <p>
                  Roadster currently works with some of the most progressive and well-known dealerships, dealership groups and OEMs in the marketplace. Current customers include: PMG’s Longo Toyota, the largest Toyota dealership in the world, Lithia, Paragon Honda and Acura and more. The company has also been named one of four of Lexus’ certified digital retail partners.
                </p>
                <p>
                  Roadster’s latest infuse of funds brings the company’s total money raised to nearly $30 Million. For more information about Roadster, its footprint and its current suite of Express Storefront®, Express Marketplace® and Express Trade® tools, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_12')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_11' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              October 24, 2017
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              New Survey Finds Almost 70 Percent of Millennials Would Accept a Job at a Car Dealership if Experience was More Like the Apple Store
            </h1>
            <div className='mt-2' style={isActive === 'release_11' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_11'
                onClick={() => setActive('release_11')}
              />
            </div>
            <div style={isActive !== 'release_11' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                Findings point to the importance millennial job seekers place on having access to the latest technology in the workplace and the influence it has over them to accept jobs in industries that they initially wanted to avoid
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, Calif. — October 24</b> — Roadster, the leader in commerce solutions for car buying and leasing, today announced the results of a survey examining millennial job satisfaction, including which industries most excite millennial job seekers and which occupations they’d like to avoid.  While Roadster’s survey, conducted in partnership with Survata, shows that the top three occupations millennials would like to avoid include becoming a politician (64 percent), a tax collector (62 percent), and a car dealer (59 percent), the survey also reveals that if these industries were to offer millennials more access to the latest technology, they would become more attractive in the eyes of job seekers.  In fact, of those polled, 54 percent of millennials said that if car dealerships employed more modern technology into the sales process and the experience felt more like the Apple store, they would be more likely to pursue a job there.</p>
                <p>
                  “In order to attract the right talent, dealers need to pay attention to what millennials want, and that’s access to technology,” said Andy Moss, CEO of Roadster.  “Our dealer partners have told us first hand that integrating technology like iPads into the sales process has not only infused a new level of excitement into their sales force, but has also helped them retain talent. In an industry known for its turnover, it is time that we truly cater to digital natives and improve the car buying and selling experience. At Roadster, we are proud to offer dealership employees access to the most modern technology available to make the entire car buying process as seamless and efficient as possible.”
                </p>
                <p>
                  Roadster’s survey, which polled 1006 millennials, furthered that while almost 2/3 of millennials are currently employed, almost half (49 percent) are actively looking for a new job.  And, when thinking about their ideal job, over 90 percent of millennials said having access to technology was important. When asked if they could model a car dealership after another retailer, millennials listed companies known for their use of modern technology in the experience, ranking Amazon (37 percent), Apple (23 percent), and Starbucks (11 percent) as their top three.
                </p>
                <p>
                  The survey also pointed to other elements about the car dealership experience that millennials believe would need change in order to want to work there.  These elements included: less high-pressured sales (61 percent), more salary, less commission based compensation (57 percent), a more predictable work schedule (30 percent) and more transparency (26 percent.)
                </p>
                <p>
                  In addition to these findings, the survey also unearthed some interesting insights about how millennials view the job market, including:
                </p>
                <ul>
                  <li>The industries that most excite millennials are (in order of ranking) technology (29 percent), healthcare (29 percent) business services/media (26 percent), hospitality (22 percent) and accounting/finance (19 percent.)</li>
                  <li>Yet while these industries are exciting to millennials, almost 2/3 of millennials polled revealed they have a list of occupations they’d like to avoid.  In fact, millennials would favor becoming a dentist (50 percent), a stock broker (55 percent), a lawyer (59 percent), and a teacher (60 percent) over becoming politician, a tax collector and a car dealer.  Yet, with access to new technology, millennials will change their minds about occupations they might otherwise state they want to avoid.</li>
                  <li>While less than two percent of women think they will find their ideal job within the automotive industry, of those polled, 67 percent stated that they would take a job at a car dealership if dealerships got rid of their high-pressured sales tactics.</li>
                  <li>These findings differ slightly when it comes to men. The survey found that while only eight percent of men think they will find their ideal job within the automotive industry, 59 percent of men would reconsider accepting a job at a car dealership, if dealerships offered more salary and less commission based compensation.</li>
                </ul>
                <p>
                  Roadster’s survey was conducted by Survata, an independent research firm in San Francisco. Survata interviewed 1006 online respondents between October 11, 2017 and October 12, 2017.   For more information about Roadster and its tech centric Express Storefront® solution, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_11')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_10' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              October 19, 2016
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster Modernizes Car Buying Experience with Introduction of In-Store App for Dealers
            </h1>
            <div className='mt-2' style={isActive === 'release_10' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_10'
                onClick={() => setActive('release_10')}
              />
            </div>
            <div style={isActive !== 'release_10' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                New app is latest extension of company’s Express Storefront® end-to-end commerce solution, empowering dealers to sell any car seamlessly from online to in-store for the first time
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, Calif. — October 19</b> — Roadster, the leader in eCommerce solutions for car buying and leasing, today announced the rollout of its newly expanded Express Storefront® solution for dealers. Now, in addition to helping dealers merchandise and sell all of their new, CPO and pre-owned inventory online, Express Storefront® will feature an in-store app that will empower each individual salesperson to assist a consumer through the entire car buying process by placing all of their dealership’s inventory, pricing, incentives and finance plans at their fingertips.</p>
                <p>
                  With the Express Storefront® app, dealers can greet a customer at the door and guide them throughdeal terms such as cash, finance or leasing, trade-in preferences, and credit information.  If the customer had already started online, they can immediately pull up all of the information the customer previously configured at home. Dealers can also use the app to locate a new VIN or model number and instantly share deal terms with the customer or their family, so that they can review every detail of the available financing, service and protection plan options. Having all this information available instantly, enables dealers to update discounts and rerun deal terms in real-time to ensure customers know exactly what to expect out of every transaction with unparalleled transparency.
                </p>
                <p>
                  “While there are a number of eCommerce solutions in the marketplace, none of them empower dealers to sell any car seamlessly from online to in-store,” said Rudi Thun, COO of Roadster. “With our white labeled Express Storefront® solution, dealers now have the benefit of increased efficiency by using a single commerce solution to merchandise and sell any car. And, because every deal is transparent and personalized, Express Storefront® actively engenders trust between the sales team and the customer. It is the solution the entire industry has been waiting for.”
                </p>
                <p>
                  In addition to the in-store app, dealers can use Roadster’s commerce platform to set up their own branded storefront, where they can merchandise all of their vehicles online and across all devices. They can automate their sales processes, empower customers to desk their own deals and navigate complex leasing and financing.  They can also provide instant trade-in offers.
                </p>
                <p>
                  Some of the most successful and progressive dealerships are already experiencing positive effects from Roadster’s Express Storefront® solution. Not only does Express Storefront® delight their customers, but it provides their sales team with a new level of efficiency and transparency in their sales process.
                </p>
                <p>
                  'We are thrilled to be using the Roadster in-store app at Ford Fairfield,” said Bill Benak, General Manager at Ford Lincoln Fairfield. “The ability to walk our customers through our inventory with complete pricing transparency has added an additional dimension of trust to our already successful one price strategy. The Roadster experience far exceeds anything else on the market. Within hours of being trained, a member of our team sold two cars back to back using Roadster.'
                </p>
                <p>
                  For more information about Express Storefront®, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_10')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_9' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              August 18, 2016
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              New Survey Finds One Third of Consumers Would Consider Buying a House or a Car Directly From Their Smartphone
            </h1>
            <div className='mt-2' style={isActive === 'release_9' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_9'
                onClick={() => setActive('release_9')}
              />
            </div>
            <div style={isActive !== 'release_9' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                Findings point to how men and women perceive purchasing big ticket items on mobile devices and debunks the myth that smartphone shopping is limited to daily items
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, Calif. — August 18</b> — Roadster, the leader in eCommerce solutions for car buying and leasing, today announced the results of a survey examining consumer perception around shopping for big ticket items on mobile devices.  While there seems to be a pervasive belief that consumers will only use their smartphones to shop for daily items, Roadster’s survey, conducted in partnership with Survata, revealed the opposite, with almost one third of people stating that they would consider buying a house or car directly from their mobile device.</p>
                <p>
                  The survey, which polled 1185 consumers, found men to be particularly amenable to the idea, with 41 percent of male respondents stating they would consider purchasing a big ticket item directly from their phone versus 21 percent of women.  It also found that consumers that fell within income brackets of $150,000 or more would be more likely to transact online.  In addition, the survey furthered that geographic location had no bearing on consumer perception, with people outside of tech specific cities stating that they too would be open to purchasing a big ticket item directly from their mobile device.
                </p>
                <p>
                  “It’s clear from the results of our survey that consumers are open to new ways of shopping for big ticket items,” said Andy Moss, CEO of Roadster.  “With Roadster, we have incorporated some of the most cutting edge ecommerce technologies into car shopping, to make car buying as easy as buying anything else online. On Roadster.com and our partner dealer sites, consumers can now find a car they like on their mobile device, purchase it directly with our ecommerce technology, and have it delivered to their doorstep, sometimes even the same day. It’s a hassle free approach that is both needed and welcomed.”
                </p>
                <p>
                  According to the survey, consumers have been clamoring for the automotive industry to adopt a variety of features of online shopping into the car buying experience; with 45 percent of consumers wishing the industry would introduce free delivery, followed by 45 percent wanting free returns and 44 percent wanting fixed pricing.  Consumers also stated that they would consider bringing even further aspects of the car buying process online, and cited their warranty selection (31 percent), price negotiation (31 percent) and service plans (27 percent) as being top areas of interest
                </p>
                <p>
                  Even those consumers who were not instantly on board with purchasing a big ticket item from their smartphones said they would reconsider if they knew that there were significant savings involved. According to respondents, 43 percent of consumers expected to save $2,000 dollars or more by purchasing a car on their smartphone versus going to the dealership and 31 percent believed they would save three to four hours by conducting the transaction online.
                </p>
                <p>
                  Although the findings of the survey showcase consumer willingness to purchase big ticket items on their smartphones, it still points to the importance of a test-drive, with with 86 percent of consumers stating that they still want to test-drive a car before purchase.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_9')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_8' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              June 30, 2016
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster Launches eCommerce Solution for Dealers
            </h1>
            <div className='mt-2' style={isActive === 'release_8' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_8'
                onClick={() => setActive('release_8')}
              />
            </div>
            <div style={isActive !== 'release_8' ? { display: 'none' } : null}>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, CA</b>—Roadster, the leader in eCommerce solutions for car buying and leasing, today announced that it will be making its best in class, mobile first, eCommerce platform available as a white labeled solution for dealers. Called Express Storefront®, Roadster’s eCommerce platform will now give dealers across the country the ability to handle more customers and capture more market-share by bringing the entire car buying experience online, from merchandising through home delivery.</p>
                <p>
                  “The positive impact our whitelabeled Express Storefront® can have on dealerships is extraordinary,” said Andy Moss, CEO of Roadster.com. “With our eCommerce platform, dealerships now have the ability to increase sales efficiency, as well as offer all of their warranties, service plans and trade-in offers instantly and transparently. It’s a way of shopping that consumers have grown to expect in every other category and we’re thrilled to be bringing this important solution to the marketplace.”
                </p>
                <p>
                  Roadster’s new offering also helps dealerships better manage their existing inventory. Today’s shopper only visits 1.6 dealerships, as compared to 5 dealerships ten years ago. Dealerships no longer need to hold all of their vehicle inventory at expensive dealership locations. By offering scheduled in-store pickups and home delivery through Express Storefront®, dealerships will be able to hold much of their inventory off-site, at less expensive locations.
                </p>
                <p>
                  It is an efficient process that has many of the largest dealer groups paying attention. In fact, in early testing, Roadster has already signed on several significant dealer groups to its new platform.
                </p>
                <p>
                  “Roadster is offering the most progressive eCommerce platform in the marketplace today,” said Mike Christian, GM Toyota Marin & Price Simms Auto Group Partner. “As an early adopter of Roadster’s Express Storefront®, we know that digital retailing is where the industry is headed. We’re excited for the opportunity to seamlessly and effectively expand our digital retailing footprint to better serve the growing needs of today’s busy, on the go shopper.”
                </p>
                <p>
                  Roadster’s new eCommerce solution comes to market right at the time when dealerships are experiencing the positive effects a consumer-centric user experience can have on their business. By enabling dealers to take the entire purchase process online, (desking, credit check, trade-in, F&I, etc.), Roadster’s solution enables car buyers to take delivery in 30 minutes or less, regardless of whether they choose home delivery or in-store pickup. Efficiency and transparency are the key tenets of Roadster’s customer-centric approach, garnering the company praise and positive reviews from customers, including a 5 star rating on Yelp.
                </p>
                <p>
                  For more information on Roadster’s Express Storefront®, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_8')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_7' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              May 25, 2016
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster Reveals 10 New Car Leases that Cost Less than a Cell Phone Payment
            </h1>
            <div className='mt-2' style={isActive === 'release_7' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_7'
                onClick={() => setActive('release_7')}
              />
            </div>
            <div style={isActive !== 'release_7' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                Just in time for the kick-off of summer car shopping season, automotive e-commerce site produces unheard of lease specials on highly coveted cars
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, CA</b>—Roadster, the “direct-to-consumer” new car buying and leasing solution, today announced that its experts have negotiated and prepared 10 new car lease specials for the month of May that cost less than a typical monthly cell phone payment. Featuring some of the most highly coveted compact sedans, hatchbacks and mid-size vehicles from Honda, Mazda, Toyota and Subaru, Roadster’s list of lease specials showcase only cars that are readily available in market and include all taxes, registration costs and fees, so there are no hidden surprises for car shoppers looking for a great deal.</p>
                <p>
                  The company’s lease specials for May include*:
                </p>
                <table className='Table mb-2'>
                  <thead>
                    <tr>
                      <th>
                        Rank
                      </th>
                      <th>
                        Year
                      </th>
                      <th>
                        Make/Model
                      </th>
                      <th>
                        Price per month including taxes and fees
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        1
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Honda Civic Sedan LX
                      </td>
                      <td>
                        $141.00 per month
                      </td>
                    </tr>
                    <tr>
                      <td>
                        2
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Mazda3 Sedan i Sport
                      </td>
                      <td>
                        $144.41 per month
                      </td>
                    </tr>
                    <tr>
                      <td>
                        3
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Honda Accord Sedan LX
                      </td>
                      <td>
                        $153.22 per month
                      </td>
                    </tr>
                    <tr>
                      <td>
                        4
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Mazda3 Hatchback i Sport
                      </td>
                      <td>
                        $165.94 per month
                      </td>
                    </tr>
                    <tr>
                      <td>
                        5
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Toyota Rav4 LE
                      </td>
                      <td>
                        $182.28 per month
                      </td>
                    </tr>
                    <tr>
                      <td>
                        6
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Mazda6 i Sport
                      </td>
                      <td>
                        $186.68 per month
                      </td>
                    </tr>
                    <tr>
                      <td>
                        7
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Subaru Legacy 2.5i
                      </td>
                      <td>
                        $201.20 per month
                      </td>
                    </tr>
                    <tr>
                      <td>
                        8
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Honda CR-V LX
                      </td>
                      <td>
                        $204.83 per month
                      </td>
                    </tr>
                    <tr>
                      <td>
                        9
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Subaru Forester 2.5i
                      </td>
                      <td>
                        $207.68 per month
                      </td>
                    </tr>
                    <tr>
                      <td>
                        10
                      </td>
                      <td>
                        2016
                      </td>
                      <td>
                        Subaru Impreza Sedan 2.0i Premium
                      </td>
                      <td>
                        $211.57 per month
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className='opacity-60 text-base mb-3'>
                  *All specials are based on 12,000 miles per year, $3500 drive-off, 36 month lease and approved tier one credit. Customized leases are also available.  Prices may slightly vary based on local tax rates.
                </p>
                <p>
                  “At Roadster, we are deeply committed to helping consumers find the car lease they want at a price point they can afford,” said Rudi Thun, COO of Roadster. “Our hands-on approach to car shopping empowers consumers to save thousands of dollars on a car they want, and do so without ever having to leave the comfort of their own homes. It’s a modern way of car shopping that works.”
                </p>
                <p>
                  Roadster is able to deliver insider pricing on a robust list of cars due to its stringent vetting and negotiation process.  Roadster’s team of ecommerce and automotive professionals begin by reviewing all of the current manufacturer lease deals in California and then work with dealer partners to slash prices on those cars they believe will be most attractive to customers. They then survey inventory and present fully transparent pricing, including all taxes, registration costs and fees, on those cars that are in plentiful supply and available for immediate purchase.
                </p>
                <p>
                  Like other ecommerce sites, all of Roadster’s lease specials can be shopped for 100 percent online.  Consumers simply select the exact car and lease terms they want, provide credit information, confirm final numbers, and then schedule delivery. Roadster’s concierge service delivers the car directly to the customer’s door and paperwork is completed within 10 minutes.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_7')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_6' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              March 22, 2016
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster Express Brings Complete e-Commerce to New Car Buying
            </h1>
            <div className='mt-2' style={isActive === 'release_6' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_6'
                onClick={() => setActive('release_6')}
              />
            </div>
            <div style={isActive !== 'release_6' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                New Program Fulfills Demand of 75% of Car Buyers Who Want to Buy Completely Online
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, CA</b>—Roadster, the “direct-to-consumer” new car buying solution, today announced the launch of Roadster Express, a revolutionary way to buy or lease a new car completely online. Roadster Express makes buying a car online as easy as buying anything else on the Web. According to a recent Accenture study, 75% of drivers state they are ready to buy a car 100% online. Using Roadster Express, the entire new car buying process can be completed online with a dealer in a matter of just minutes, without any phone or email communication.</p>
                <p>
                  With Roadster Express, new car buyers can now:
                </p>
                <ol>
                  <li>
                    Configure the car they want and instantly see the entire deal online, including all fees and taxes.
                  </li>
                  <li>
                    Fill out an online credit application and schedule home delivery of the car.
                  </li>
                  <li>
                    Bid out their trade-in to a network of dealers and professional buyers.
                  </li>
                  <li>
                    Complete all transaction paperwork in 10 minutes or less—right from the comfort of home.
                  </li>
                </ol>
                <p>
                  Roadster works with multiple dealerships for each brand, resulting in highly competitive prices that save buyers an average of $3,688 off MSRP. Roadster also helps shoppers maximize savings by searching inventory and finding cars with only the features needed, identifying the best combination of rebates and incentives, and securing the lowest possible interest rate or lease.
                </p>
                <p>
                  “9-out-of-10 shoppers today dread the car buying process and want to outsource some part of the experience,” said Andy Moss, Roadster CEO. “Pair this with the growing acceptance of buying big ticket items online and it’s clear that the time is now to enhance the new car buying experience in a big way. Roadster Express is the solution that finally brings 100% online car buying to market in the U.S.”
                </p>
                <p>
                  For dealerships, Roadster provides a new and efficient sales channel, delivering closed sales as opposed to traditional leads, with no additional overhead required. Dealers set their price and Roadster facilitates the transaction. Currently, Roadster Express is exclusively available on Roadster.com, but an e-commerce solution for dealer websites is forthcoming. 'Roadster customers are looking for a great price. They also want the convenience of buying a car as simply as everything else they buy online,' continued Moss.
                </p>
                <p>
                  Buyers interested in selling their current vehicle can also receive a hassle-free, competitive offer for their trade-in within 48 hours by using Roadster’s recently launched Trade-In program. At the time of their new car delivery, buyers receive a check for their trade-in, sign a few documents, and hand over their keys to their Roadster Delivery Specialist.
                </p>
                <p>
                  Roadster Express is immediately available for 25 models in the state of California, with expansion to additional states and models planned throughout this year.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_6')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_5' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              February 11, 2016
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Auto Industry Survey Reveals Half of Consumers Consider Buying Next New Car Online
            </h1>
            <div className='mt-2' style={isActive === 'release_5' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_5'
                onClick={() => setActive('release_5')}
              />
            </div>
            <div style={isActive !== 'release_5' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                Roadster.com Survey Shows Consumer Sentiment Rapidly Evolving Around Car Buying
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, CA</b>—Roadster, the “direct-to-consumer” new car buying solution, today announced the results of an independently conducted trend survey focused on emerging car buying behaviors. Among its most dramatic findings, the omnibus survey found that half of new car buyers will consider buying their next car fully online. In support, better prices and selection topped reasons for readiness to buy online.</p>
                <p>
                  “People have become very comfortable making online purchases, especially when it saves them time and money. Loyalty and trust are built by companies who can consistently deliver on that promise,” said Roadster CEO, Andy Moss. “Online car buying has already taken off across Europe. It was only a matter of time before American consumers began to demand similar conveniences. Tesla's early success with online buying is a leading indicator of adoption.”
                </p>
                <p>
                  Featured survey results spanned opinions about dealers, motivations to buy online and even the most dreaded aspects of new car buying. Highlight findings included:
                </p>
                <ul>
                  <li>
                    One out of two car buyers will consider a 100% online purchase of their next car
                  </li>
                  <li>
                    Better prices and vehicle selection were the top reasons given for readiness to buy online
                  </li>
                  <li>
                    90% of car buyers dread some portion of the car buying process
                  </li>
                  <li>
                    Price negotiation was the most dreaded part of the process, with time spent a close second
                  </li>
                  <li>
                    While 36% of recent car buyers would have skipped the dealership if they could, the majority noted that even if they buy online, they still want to go to the dealership for test drives and service
                  </li>
                  <li>
                    Further illustrating the desire for a “no hassle” experience, a majority of consumers would pay a small fee for a concierge service, including home delivery and at-home paperwork
                  </li>
                </ul>
                <p>
                  Conducted in December 2015 by Survata consumer research, the car shopper trend survey commissioned by Roadster polled 1,000 U.S. consumers who have purchased a car in the past 12-months or intend to do so in the next 12-months.
                </p>
                <h3>
                  How does Roadster work?
                </h3>
                <p>
                  Roadster dramatically improves the new car buying and leasing process by providing a complete buying experience from shopping for to receiving a car. With Roadster, consumers can explore, compare and look for their perfect car online among hundreds of thousands of vehicles and instantly see available inventory, including specific options and packages. The Roadster Concierge team then works with dealers on the consumer's behalf to source cars and get a great price.
                </p>
                <p>
                  The Concierge team is made up of helpful, unbiased pros who work on all aspects of the purchase or lease process so consumers can avoid the many hours typically spent negotiating in the finance office of a car dealership. Before making any commitment, the consumer receives a simple Deal Sheet that outlines every aspect of the transaction including cash, lease, or finance alternatives. All paperwork is printed in advance and takes less than 15 minutes to sign as part of the delivery of the new car to their home or office. Roadster also helps consumers maximize the trade-in value of their existing car.
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_5')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_4' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              December 02, 2015
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster’s New Trade-In Experience Makes Selling Your Car a Breeze
            </h1>
            <div className='mt-2' style={isActive === 'release_4' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_4'
                onClick={() => setActive('release_4')}
              />
            </div>
            <div style={isActive !== 'release_4' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                Innovator in Car Buying Introduces a Competitive Marketplace Featuring Multiple Bidders and a Firm Offer Within 48-Hours; Delivers the Convenience and Time Savings of Existing Trade-in Models, While Yielding a Much Better Sale Price
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, CA</b>—Roadster, the direct-to-consumer new car buying solution, today announced the release of a next generation, smartphone enhanced, trade-in experience. In addition to offering a unique, stress-free car buying experience, Roadster now provides customers a better alternative to accepting lowball offers for their trade-in from a dealer or investing time and effort selling to a private party.</p>
                <p>
                  With its new quick and easy trade-in solution, Roadster solicits bids on trade-ins from a network of professional buyers, creating a competitive marketplace for those cars. Within 48-hours, customers are provided a firm cash offer that is typically hundreds, if not thousands, higher than a dealer might offer. If they accept the offer, Roadster arranges for home or office pickup, confirms the car’s condition, drops off a check, and handles all the required DMV paperwork.
                </p>
                <p>
                  “By getting multiple bids, Roadster's new solution generates top dollar offers, but equally important, it provides customers with the most efficient and stress-free way to dispose of their old car,” said Roadster CEO Andy Moss. “Over 45% of new car buyers trade-in their used cars during the purchase process, knowingly accepting a below market price in exchange for convenience. With Roadster, our customers do not need to compromise between convenience and price. They get the best of both worlds.”
                </p>
                <p>
                  Optimized for smartphones, the Roadster product guides sellers through a short process to capture vehicle details and photos. “We wanted to harness the power of mobile to help our customers with the challenge of getting a competitive price for their used car, without making it cumbersome or requiring a detailed upfront inspection,” said Moss. The new trade-in offering is initially available throughout California and can be leveraged by sellers regardless of whether or not they are purchasing a new vehicle with Roadster.
                </p>
                <h3>
                  How does Roadster work?
                </h3>
                <p>
                  Roadster dramatically improves the new car buying and leasing process by providing a complete online buying experience from shopping for to receiving a car. With Roadster, consumers can explore, compare and look for their perfect car online among hundreds of thousands of vehicles and instantly see available inventory, including specific options and packages. The Roadster Concierge team then works with dealers on the consumer's behalf to source cars and get a great price.
                </p>
                <p>
                  The Concierge team is made up of helpful, unbiased pros who work on all aspects of the purchase or lease process so consumers can avoid the many hours typically spent negotiating in the finance office of a car dealership. Before making any commitment, the consumer receives a simple Deal Sheet that outlines every aspect of the transaction including cash, lease, or finance alternatives. All paperwork is printed in advance and takes less than 15 minutes to sign as part of the delivery of the new car to their home or office. Roadster also helps consumers maximize the trade-in value of their existing car.
                </p>
                <p>
                  Roadster.com was developed to address the increasingly web-savvy car buying public who purchased 16.5 million new cars in the U.S. in 2014, up 5.9% from the previous year (Source: NADA).
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_4')}
                  />
                </div>
              </div>
            </div>
          </section>
          <Anchor id='release_3' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              June 22, 2015
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Roadster Unveils the Transparent Car Lease: Great Pricing and No Surprises
            </h1>
            <div className='mt-2' style={isActive === 'release_3' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_3'
                onClick={() => setActive('release_3')}
              />
            </div>
            <div className='PressRelease-body mt-3 sm:text-base leading-relaxed' style={isActive !== 'release_3' ? { display: 'none' } : null}>
              <p><b>San Francisco, CA</b>—Roadster, the “direct-to-consumer” new car buying solution, today announced Roadster Leases. Featuring exclusive monthly deals sourced by the Roadster team, Roadster Leases finally takes the fear and confusion out of leasing a new car by offering hassle-free, complete and transparent pricing. Consumers can now avoid hidden fee surprises and the disappointment of learning that the lease deal they are being offered is only available for a base model they don’t really want.</p>
              <p>
                First, Roadster launched the no-hassle, concierge car buying experience that finally made buying a car online as easy as buying anything else. Starting today, Roadster is further enhancing its leasing experience, bringing even more transparency, simplicity, and affordability to the vehicle leasing process.
              </p>
              <p>
                “Whether you want to have a lower monthly payment, always drive a new car, or enjoy the most up-to-date technology, there are endless reasons why people would prefer to lease,” said Rudi Thun, Roadster’s COO. “Roadster has painstakingly researched, rebuilt, and demystified the leasing experience. We have shed more light on pricing, eliminated the haggling, and extracted the fear, uncertainty and doubt so many feel about the leasing process.”
              </p>
              <p>
                Roadster Leases is now available throughout California and features:
              </p>
              <ul>
                <li><b>Exclusive deals:</b> Great deals, negotiated and curated by Roadster experts across a wide variety of vehicle categories.</li>
                <li><b>No hassles:</b> Pre-negotiated vehicle terms and pricing.</li>
                <li><b>No surprises:</b> Roadster shows you the drive-off amount and monthly payment inclusive of taxes, registration and fees, down to the last penny.</li>
                <li><b>Selection:</b> Shop across cars and easily see what you can afford at different monthly payments.</li>
                <li><b>Compare:</b> Cross-shop lease&nbsp;deals&nbsp;for similar models side-by-side with the same drive-off prices, term lengths, and mileage allowances.</li>
              </ul>
              <h3>
                How does Roadster work?
              </h3>
              <p>
                Roadster dramatically improves the new car buying and leasing process by providing a complete online buying experience from shopping for, to receiving a car. With Roadster, consumers can explore, compare and look for their perfect car online among hundreds of thousands of vehicles and instantly see available inventory, including specific options and packages. The Roadster Concierge team then works with dealers on the consumers’ behalf to source cars and get a great price.
              </p>
              <p>
                A key part of the Roadster experience, the Concierge team is made up of helpful, unbiased pros who work on all aspects of the purchase or lease process so consumers can avoid the many hours typically spent negotiating in the finance office of a car dealership. Before making any commitment, the consumer receives a simple Deal Sheet that outlines every aspect of the transaction including cash, lease, or finance alternatives. All paperwork is printed in advance and takes less than 15-minutes to sign as part of the delivery of the new car to their home or office. Roadster also helps consumers maximize the trade-in value of their existing car.
              </p>
              <p>
                Roadster.com was developed to address the increasingly web-savvy car buying public who in 2014 purchased 16.5 million new cars in the U.S., up 5.9% from the previous year (Source: NADA).
              </p>
              <h3>
                About Roadster
              </h3>
              <p>
                Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
              </p>
              <div className='mt-4'>
                <Link
                  variant='muted'
                  children='Collapse'
                  className='uppercase font-semi-bold'
                  onClick={() => handleCollapse('release_3')}
                />
              </div>
            </div>
          </section>
          <Anchor id='release_2' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              March 25, 2015
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              Online Automotive Pioneer Rudi Thun Joins Roadster, the New Car Buying Solution, As Chief Operating Officer
            </h1>
            <div className='mt-2' style={isActive === 'release_2' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_2'
                onClick={() => setActive('release_2')}
              />
            </div>
            <div className='PressRelease-body mt-3 sm:text-base leading-relaxed' style={isActive !== 'release_2' ? { display: 'none' } : null}>
              <p><b>San Francisco, CA</b>—Roadster, the “direct-to-consumer” new car buying solution, today announced that Rudi Thun has been named the company’s Chief Operating Officer (COO). Thun has over 15 years of executive experience in online automotive, where he has driven the growth of some of the industry’s largest and most powerful brands.</p>
              <p>
                Thun will join Roadster’s executive team, leading the company’s marketing, business development and content operations, with the goal of enhancing and expanding Roadster’s industry footprint.
              </p>
              <p>
                “We have a bold mission to make buying your new car online as easy as buying anything else,” said Andy Moss, Roadster co-founder and CEO. “More than any other person in the industry, Rudi understands that goal and has spent the last 15 years working to successfully make it a reality. With unmatched experience and a track record of success, Rudi will help our team make Roadster one of the most respected brands in automotive sales.”
              </p>
              <p>
                Previously the Head of Vehicles for eBay Motors, Thun managed the day-to-day operations of eBay’s vehicle marketplace.&nbsp;Prior to eBay he was COO of CarWoo!, an early stage new car marketplace and held the role of VP and General Manager of AOL’s automotive properties, AOL Autos and Autoblog.com.&nbsp;
              </p>
              <p>
                “I’ve joined Andy and Roadster because they understand what it takes to meaningfully improve today’s online and offline car buying experiences,” said Thun. “They have paired an unmatched Concierge team with an innovative online offering that gives consumers peace of mind while also saving them time, money and hassle.”
              </p>
              <p>
                Roadster was developed to address the increasingly web-savvy car buying public who in 2014 purchased 16.5 million new cars in the U.S., up 5.9% from the previous year. (Source: NADA)
              </p>
              <p>
                Roadster dramatically improves the new car buying process by providing a complete online buying experience from shopping for, to receiving a car. With Roadster, consumers can explore, compare and look for their perfect car among hundreds of thousands of vehicles and instantly see available inventory. The Roadster Concierge team then works with dealers on the consumers’ behalf to locate cars, get the best market price, and then deliver the car to the buyer’s home or workplace.
              </p>
              <h3>
                About Roadster
              </h3>
              <p>
                Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
              </p>
              <div className='mt-4'>
                <Link
                  variant='muted'
                  children='Collapse'
                  className='uppercase font-semi-bold'
                  onClick={() => handleCollapse('release_2')}
                />
              </div>
            </div>
          </section>
          <Anchor id='release_1' />
          <section className='PressRelease p-4 mb-3 border border-mid-gray'>
            <div className='PressRelease-date text-gray font-semi-bold uppercase mb-1'>
              February 24, 2015
            </div>
            <h1 className='PressRelease-title font-semi-bold text-lg sm:text-xl mt-0 mb-1 leading-normal text-gray'>
              E-Commerce and Automotive Experts Debut Roadster:
              Making New Car Buying as Easy as Purchasing Anything Else Online
            </h1>
            <div className='mt-2' style={isActive === 'release_1' ? { display: 'none' } : null}>
              <Link
                className='uppercase font-semi-bold'
                variant='muted'
                arrowDirection='down'
                children='Read more'
                href='#release_1'
                onClick={() => setActive('release_1')}
              />
            </div>
            <div style={isActive !== 'release_1' ? { display: 'none' } : null}>
              <h2 className='PressRelease-subtitle text-base mt-2 sm:text-md leading-relaxed'>
                Roadster Launches Publicly and Announces Seed Funding
              </h2>
              <div className='PressRelease-body mt-3 sm:text-base leading-relaxed'>
                <p><b>San Francisco, CA</b>—Roadster, the “direct-to-consumer” new car buying solution, today launched publicly and announced a strategic seed investment round of $1,750,000. Unlike other online auto services that are largely do-it-yourself or lead generation tools for dealerships, Roadster provides consumers with the ease, convenience and savings of online shopping without the time-consuming hassles associated with buying a new car.</p>
                <p>
                  When the consumer is ready to buy, Roadster’s unbiased Concierge team handles everything on behalf of the consumer from obtaining the best market price through delivery of the car to their home or office. Roadster is available initially for the California market, representing approximately 12% of U.S. auto sales.
                </p>
                <p>
                  “Online offerings have evolved to tackle the once unthinkable categories of travel, clothing, and real estate. Now, Roadster puts car buying in the same ‘solved’ category,” said Andy Moss, Roadster co-founder and CEO. ”With Roadster, we aimed to improve car buying to the level that people enjoy, not dread the process. We absorbed all the pain points, such as shopping, locating, negotiating and buying, into a simple, easy online experience. We save consumers money and time buying a car that’s perfect just for them.”
                </p>
                <p>
                  Roadster’s founders developed Roadster.com to address the increasingly web-savvy car buying public who in 2014 purchased 16.5 million new cars in the U.S., up 5.9% from the previous year. (Source: NADA)
                </p>
                <h3>
                  How does Roadster work?
                </h3>
                <p>
                  Roadster dramatically improves the new car buying process by providing a complete online buying experience from shopping for, to receiving a car. With Roadster, consumers can explore, compare and look for their perfect car among hundreds of thousands of vehicles and instantly see available inventory. The Roadster Concierge team then works with dealers on the consumers’ behalf to locate cars and get the best market price.
                </p>
                <p>
                  A key part of the Roadster experience, the Concierge team is made up of helpful, unbiased pros who work on all aspects of the purchase or lease process so consumers can avoid the many hours typically spent negotiating or sitting in the finance office of a car dealership. Before making any commitment, the consumer receives a simple Deal Sheet that outlines every aspect of the transaction including cash, lease, or finance alternatives. All paperwork is printed in advance and takes less than 15 minutes to sign as part of the delivery of the new car to their home or office. Roadster also helps consumers maximize the trade in value of their existing car.
                </p>
                <p>
                  Leading Roadster’s seed funding are: Brian Sugar, CEO, PopSugar; Scott Banister, Co-Founder
                  and Chairman at Zivity; Adam Goldenberg, CEO of JustFab; Raad Mobrem, CEO of Lettuce; and Nir Liberboim, Founder of Uprise Ventures. Additional well known Roadster angel investors include Mark Goldstein and Don Hutchison. “It’s clear that Andy and the Roadster leadership team have more experience, expertise and vision in the e-commerce and online automotive purchasing market than almost anyone else today,” said Scott Banister, Co-Founder and Chairman at Zivity and Roadster investor. “What makes Roadster so unique from other services is its unsurpassed visibility into available auto inventory, its 100% transparent and upfront pricing and the dedication to empower consumers with broad unbiased choices, so they can identify and buy the exact car they want, with no hassles. Roadster is the long-awaited one-stop source for buying a new car.”
                </p>
                <h3>
                  About Roadster
                </h3>
                <p>
                  Roadster provides consumer-driven Commerce Solutions for today’s modern dealership. With Roadster's proprietary technology platform, dealerships can provide hassle-free car buying in-store, online or on the go. Roadster dramatically improves dealership customer satisfaction scores, while significantly reducing sales costs. From inventory merchandising, to financing/leasing, incentives, trade-ins, service plans and accessories-- Roadster delivers near penny perfect deals in a beautifully designed interface that customers and employees will love. Roadster is based in Palo Alto, CA and was founded in late 2013. For more information, please visit
                  {' '}
                  <Link
                    href='https://roadster.com'
                    variant='muted'
                    children='Roadster.com'
                  />.
                </p>
                <div className='mt-4'>
                  <Link
                    className='uppercase font-semi-bold'
                    variant='muted'
                    arrowDirection='up'
                    children='Collapse'
                    onClick={() => handleCollapse('release_1')}
                  />
                </div>
              </div>
            </div>
          </section>
        </Container>
      </div>
    </Layout>
  )
}

export default PressReleasesPage
