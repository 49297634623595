import * as React from 'react'
import Subnav from './subnav'
import Container from './container'
import { StaticImage } from 'gatsby-plugin-image'

const AboutHeader = ({ title, subtitle, isPress, children }) => (<>
  <div
    className='flex flex-col justify-center relative bg-black text-white'>
    <StaticImage
      src='../images/00_Hero_ab.jpg'
      quality={100}
      alt=''
      placeholder='blurred'
      imgClassName='object-bottom'
      className='absolute inset-0'
    />
    <Container className='pb-4 pt-5 sm:pb-5 relative'>
      {title &&
        <h1 className='font-semi-bold text-4vw'>
          {title}
        </h1>
      }
      {subtitle &&
        <p className='text-silver text-md sm:text-lg mb-0'>
          {subtitle}
        </p>
      }
      {children}
    </Container>    
  </div>
  <Subnav
    links={isPress ? [
      {
        to: '/about',
        text: 'About'
      },
      {
        to: '/press',
        text: 'Press Coverage'
      },
      {
        to: '/press_releases',
        text: 'Press Releases'
      },
      {
        to: '/media_resources',
        text: 'Media Resources'
      },
      {
        to: '/contact',
        text: 'Contact'
        }
    ] : [
      {
        to: '/about',
        text: 'Overview'
      },
      {
        to: '/press',
        text: 'Press'
      },
      {
        to: '/team',
        text: 'Team'
      },
      {
        to: '/careers',
        text: 'Careers'
      },
      {
        href: 'https://blog.roadster.com',
        text: 'Blog'
      },
      {
        to: '/contact',
        text: 'Contact'
      }
    ]}
  />
</>)

export default AboutHeader
