import React, { useState, useEffect, useRef } from 'react'
import Container from './container'
import Link from './link'
import ScrollSpy from './scrollspy'
import Box from './box'

const Subnav = ({ isSticky, links }) => {
  
  const scrollRef = useRef(null)
  const [offset, setOffset] = useState(null)

  useEffect(() => {

    if (scrollRef.current.scrollWidth > scrollRef.current.clientWidth) {
      scrollRef.current.querySelector('.is-active')?.scrollIntoView({ inline: 'center', block: 'end' })
    }

    if (isSticky) {
      const handleResize = () => {
        const header = document.getElementById('header')
        const newOffset = header.offsetHeight + scrollRef.current.offsetHeight
        if (offset !== newOffset) {
          setOffset(newOffset)
        }
        return true
      }
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    
  })

  return (
    <div
      id='subnav'
      className={[
        'shadow',
        'bg-white',
        isSticky ? 'sticky' : 'relative',
        isSticky ? 'z-2' : 'z-1'
      ].join(' ')}
      style={isSticky ? { top: 'var(--header-height)' } : null}>
      <Container>
        <Box
          ref={scrollRef}
          css={{
            height: 'var(--subnav-height)',
            '&::-webkit-scrollbar': { display: 'none' }
          }}
          className='overflow-auto px-container -mx-container whitespace-nowrap'>
          <ScrollSpy offset={offset}>
            {links.map(link => 
              <Link
                key={`Subnav ${link.to || link.href}`}
                variant='muted'
                className='border-t-2 border-b-2 border-transparent mx-2 lg:mx-3 first:ml-0 last:mr-0 h-full'
                {...link.to ? {
                  to: link.to,
                  activeClassName: 'is-active border-b-black',
                  partiallyActive: true
                } : {
                  href: link.href,
                  ref: React.createRef()
                }}>
                <span
                  className='uppercase font-semi-bold flex items-center h-full'
                  children={link.text} />
              </Link>
            )}
          </ScrollSpy>
        </Box>
      </Container>
    </div>
  )
}

export default Subnav
