/* eslint-disable no-undef */
import React, { useEffect } from 'react'

const ScrollSpy = ({
  className = 'is-active',
  offset = 0,
  children,
}) => {
  useEffect(() => {
    const sourceElements = []
    const targetElements = []

    const throttle = (fn, wait = 100) => {
      let timer
      let time = Date.now()

      return (params) => {
        clearTimeout(timer)

        if (time + wait - Date.now() < 0) {
          fn(params)
          time = Date.now()
        } else {
          timer = setTimeout(fn, wait / 5)
        }
      }
    }

    const onScrollHandler = throttle(() => {
      const scrollElement = document.scrollingElement || document.documentElement

      const center = scrollElement.scrollTop + window.innerHeight / 2

      sourceElements.map((source, i) => {
        const target = targetElements[i]

        const visible =
          target.offsetTop >= 0 &&
          center >= target.offsetTop &&
          center < target.offsetTop + target.offsetHeight

        if (visible) {
          source.classList.add(className)
        } else {
          source.classList.remove(className)
        }

        return true
      })
    })

    children.map((el) => {
      const href = el.props && el.props.href
      const self = el.ref && el.ref.current

      if (!self || !href || href.charAt(0) !== '#') {
        return false
      }

      const targetElement = href === '#' ? document.body : document.querySelector(href)

      if (targetElement) {
        self.onclick = (e) => {
          e.preventDefault()
          window.scrollTo({
            top: targetElement.offsetTop - offset,
            behavior: 'smooth'
          })
        }
        targetElements.push(targetElement)
        sourceElements.push(self)
      }

      return true
    })

    if (targetElements.length) {
      const ScrollEvent = new Event('scroll')
      window.addEventListener('scroll', onScrollHandler, { passive: true })
      window.dispatchEvent(ScrollEvent)
    }

    return () => {
      window.removeEventListener('scroll', onScrollHandler)
    }
  }, [children, className, offset])

  return <>{children}</>
}

export default ScrollSpy
